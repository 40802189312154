<template>
  <b-modal
    id="modal-page-remove-request-sended"
    centered
    hide-footer
    body-class="pt-0"
  >
    <div
      class="col text-center align-items-center px-3"
    >
      <h3 class="font-weight-bold mb-1">
        {{ $t('Page removal request is sent!') }}
      </h3>
      <h5 class="mb-1">
        {{ $t('Your request has been successfully submitted. System admin will review it shortly. If the request is approved, your Page with the content belongs to this page will be removed') }}
      </h5>
      <div class="flex-row d-flex justify-content-center">
        <b-button
          variant="primary"
          @click="hideModal"
        >
          {{ $t('Okay') }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>

import {
  BModal,
  BButton,
} from 'bootstrap-vue'

import {
  permissions,
} from '@/config/config'

export default {
  components: {
    BModal,
    BButton,
  },
  data() {
    return {
      permissions,
    }
  },
  mounted() {
    EventBus.$on('showPageRemoveRequestSendedModal', () => {
      this.$root.$emit('bv::show::modal', 'modal-page-remove-request-sended', '#focusThisOnClose')
    })
  },
  methods: {
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-page-remove-request-sended')
    },
  },
}
</script>
