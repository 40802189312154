<template>
  <div>
    <profile-settings-button
      v-if="canInteractCommentSettings"
      :value="is_comments_allowed"
      :title="$t('Comments to posts')"
      :description="$t('Everyone can comment posts on my page.')"
      @click="setAllowComments"
    />
    <profile-settings-button
      v-if="is_owner"
      :value="hide_status"
      :title="$t('Hide status')"
      :description="$t('If your page is “Hide” it won’t be visible to other users of application.')"
      @click="setHideProfile"
    />
    <b-button
      v-if="is_owner"
      class="font-weight-bold"
      variant="outline-primary"
      @click="showModal('showPageRemoveRequestModal')"
    >
      {{ $t('Request page removal') }}
    </b-button>
    <page-remove-request-modal @requestSended="showModal('showPageRemoveRequestSendedModal')" />
    <page-remove-request-sended-modal />
  </div>
</template>

<script>

import { BButton } from 'bootstrap-vue'

import ProfileSettingsButton from '@/components/profile-company/ProfileSettings/ProfileSettingsButton.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PageRemoveRequestModal from '@/components/modal/PageRemoveRequest.vue'
import PageRemoveRequestSendedModal from '@/components/modal/PageRemoveRequestSended.vue'

export default {
  components: {
    ProfileSettingsButton,
    PageRemoveRequestModal,
    PageRemoveRequestSendedModal,

    BButton,
  },
  data() {
    return {
      is_comments_allowed: false,
      hide_status: false,
      is_owner: false,
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
    canInteractCommentSettings() {
      return this.user.active_business_profile.slug === this.$route.params.slug && (this.user.active_business_profile.role === 'owner' || this.user.active_business_profile.role === 'admin')
    },
  },
  async created() {
    await this.fetchCompany()
    const company = this.$store.getters['profileCompany/company']
    this.hide_status = !company.is_published
    this.is_comments_allowed = company.is_comments_allowed
    this.is_owner = this.$store.getters['auth/user'].slug === company.owner.slug
  },
  methods: {
    showModal(modalName) {
      EventBus.$emit(modalName)
    },
    async setHideProfile() {
      const res = await this.$store.dispatch('profileCompany/setHideProfile', {
        slug: this.$route.params.slug,
        isPublished: this.hide_status,
      })
      if (res.status === 200) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('The new page status is applied'),
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        })
      } else {
        this.hide_status = !this.hide_status
      }
    },
    async setAllowComments() {
      const res = await this.$store.dispatch('profileCompany/setAllowComments', {
        slug: this.$route.params.slug,
        isCommentsAllowed: !this.is_comments_allowed,
      })
      if (res.status === 200) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('New Commenting rules are applied'),
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        })
      } else {
        this.is_comments_allowed = !this.is_comments_allowed
      }
    },
    async fetchCompany() {
      await this.$store.dispatch('profileCompany/fetchCompany', this.$route.params.slug)
    },
  },
}
</script>
