<template>
  <b-modal
    id="modal-page-remove-request"
    centered
    hide-footer
    body-class="pt-0"
  >
    <div
      class="col text-center align-items-center px-3"
    >
      <h3 class="font-weight-bold mb-1">
        {{ $t('Request page removal') }}
      </h3>
      <h5 class="mb-1">
        {{ $t('Please add the reason why you want your page to be removed by an admin?  ') }}
      </h5>
      <b-form-textarea
        v-model="text"
        class="mb-2"
        placeholder="Write here..."
        required
      />
      <div class="flex-row d-flex justify-content-center">
        <b-button
          variant="link"
          class="mr-1"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          variant="primary"
          @click="sendRequest"
        >
          {{ $t('Send request') }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>

import {
  BModal,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BFormTextarea,
    BButton,
  },
  props: {
    isCompany: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      text: '',
    }
  },
  mounted() {
    EventBus.$on('showPageRemoveRequestModal', () => {
      this.$root.$emit('bv::show::modal', 'modal-page-remove-request', '#focusThisOnClose')
    })
  },
  methods: {
    async sendRequest() {
      const respnse = await this.$store.dispatch(`${this.isCompany ? 'profileCompany' : 'auth'}/sendDeletePageRequest`, { slug: this.$route.params.slug, reason_to_delete: this.text })
      if (respnse.status === 204) {
        this.$emit('requestSended')
        this.hideModal()
      }
    },
    showButton(permission) {
      return this.isOwnerOrAdmin || this.isEditor || this.checkPermission(permission, this.userPermissions)
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-page-remove-request')
    },
  },
}
</script>
