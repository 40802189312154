<template>
  <div class="profile-settings-button">
    <div>
      <h5 class="font-weight-bold mb-0">
        {{ title }}
      </h5>
      <span class="text-muted">{{ description }}</span>
    </div>
    <b-form-checkbox
      v-model="content"
      switch
      @change="changeStatus"
    />
  </div>
</template>

<script>

import { BFormCheckbox } from 'bootstrap-vue'

export default {
  components: {
    BFormCheckbox,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      content: this.value,
    }
  },
  watch: {
    value(newValue) {
      this.content = newValue
    },
  },
  methods: {
    changeStatus() {
      this.$emit('click')
    },
  },
}
</script>
